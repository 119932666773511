import { useState, useEffect } from 'react';
import axios from "axios";
import ReactApexChart from "react-apexcharts";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import moment from 'moment';

export default function SbcPast() {
  // const url = "http://localhost:4100";
  const url = 'https://dev.pmwevents.com'

  const [tickets, setTickets] = useState([])
  const [sales, setSales] = useState(0);
  const [totalTickets, setTotalTickets] = useState(0);
  const [totalAttendees, setTotalAttendees] = useState(0);
  const [salesData, setSalesData] = useState([]);
  const [adminSales, setAdminSales] = useState([]);
  const [datesData, setDatesData] = useState([]);
  const [sbcEvents, setSbcEvents] = useState([]);
  const [event, setEvent] = useState('');

  useEffect(() => {
    axios.get(`${url}/sbc/sbc-getpass`)
      .then((res) => {
        let events = res.data;
        for (let e = 0; e < events.length; e++) {
          events[e].eventName = `SBC - ${moment(events[e].eventDate).format('DD-MM-YYYY')}`
        }
        if (events.length === 1) {
          console.log(events[0].eventName)
          setEvent(events[0].eventName)
          axios.get(`${url}/sbc/dashboard?id=${events[0].eventId}`)
            .then((res) => {
              console.log(res.data)
              setTickets(res.data.tickets)
              setSales(res.data.sales)
              setTotalTickets(res.data.attendees)
              setTotalAttendees(res.data.totalAttendees)
              setSalesData(res.data.web)
              setAdminSales(res.data.admin)
              setDatesData(res.data.dates)
            }).catch((err) => {
              console.log(err)
            })

        } else {
          setSbcEvents(events)
        }
      }).catch((err) => {
        console.log(err)
      })
  }, [])

  // CHART
  let salesChart = {
    series: [{
      name: 'Web Sales',
      data: salesData
    }, {
      name: 'Admin Tickets',
      data: adminSales
    }],
    options: {
      chart: {
        type: 'bar',
        height: 350,
        width: 750,
        // background: "#fff",
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded'
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: datesData,
      },
      yaxis: {
        title: {
          text: 'Ticket Sales per Day'
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val + "  Tickets"
          }
        }
      }
    },
    responsive: [{
      breakpoint: 750,
      options: {
        width: 450
      }
    }],
  }

    const handleEventSelect = async (event) => {
    console.log(event)
    for (let s = 0; s < sbcEvents.length; s++) {
      if (sbcEvents[s].eventId == event) {
        console.log('found event')
        setEvent(sbcEvents[s].eventName)
      }
    }
    // TODO: get event details
    axios.get(`${url}/sbc/dashboard?id=${event}`)
      .then((res) => {
        console.log(res.data)
        setTickets(res.data.tickets)
        setSales(res.data.sales)
        setTotalTickets(res.data.attendees)
        setTotalAttendees(res.data.totalAttendees)
        setSalesData(res.data.web)
        setAdminSales(res.data.admin)
        setDatesData(res.data.dates)
      }).catch((err) => {
        console.log(err)
      })
  }


  return (
    <section className="dash__main">
      {event === '' ? (
        <div className="dash__data">
          <h2>Select Event To See Data</h2>
          <FormControl className="form--select">
            <InputLabel id="select-label">Select an Event</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={event}
              label="Age"
              onChange={(e) => handleEventSelect(e.target.value)}
              sx={{ backgroundColor: "#fff" }}
            >
              {sbcEvents.map((event) => {
                return (
                  <MenuItem key={event.eventId} value={event.eventId}>
                    {event.eventName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
      ) : (
        <div className="dash__data">
          <h2>{event}</h2>
          <section className="dash__row">
            <div className="dash__card">
              <h3>£{parseFloat(sales).toFixed(2)}</h3>
              <h6>Sales</h6>
            </div>
            <div className="dash__card">
              <h3>{totalTickets}</h3>
              <h6>Total Ticket Sales</h6>
            </div>
            <div className="dash__card">
              <h3>{totalAttendees}</h3>
              <h6>Total Attendees</h6>
            </div>

          </section>
          <table>
            <thead>
              <th>Ticket Name</th>
              <th>Ticket Sales / Capacity</th>
            </thead>
            <tbody>
              {tickets.map((ticket) => {
                return (
                  <tr>
                    <td>{ticket.ticketName}</td>
                    <td>{ticket.ticketSales} / {ticket.maxTickets}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>

          <ReactApexChart
            options={salesChart.options}
            series={salesChart.series}
            type="bar"
            width={800}
          />
        </div>
      )}
    </section>
  )
}