import logo from '../images/logo.png';
import { TextField, Button } from '@mui/material'
import '../styles/login.scss';
import { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export default function Login() {
  const [user, setUser] = useState('');
  const [password, setPassword] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState("");
  const url = 'https://dev.pmwevents.com'
    // const url = 'http://localhost:4100'
  const navigate = useNavigate()

  const login = () => {
    if (user === '' || password === '') {
      setMessage('Please insert User Name and Password')
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false)
      }, 2500)
    } else {
      let loginUser = {
        username: user,
        password: password,
      }
      // axios call to the BE
      axios.post(`${url}/login`, loginUser)
        .then((res) => {
          console.log(res.data)
          if (res.data.firstTime === true) {
            navigate(`/password-change?user=${res.data.userId}&access=${res.data.access}`)
          } else {
            localStorage.setItem('access', res.data.access)
            localStorage.setItem('user', res.data.userId)
            navigate('/menu')
          }
        }).catch((err) => {
          console.log(err)
          setMessage(err.response.data.msg)
          setShowAlert(true)
          setTimeout(() => {
            setShowAlert(false)
          }, 2000)
        })
    }
  }

  const enter = (e) => {
    if (e.keyCode === 13){
      login()
    }
  }



  return (
    <main className='login__page'>
      <section className='login__form_wrapper'>
        <img src={logo} alt='PMW Wings' />
        <article className='login__form'>
          <h1>Please login</h1>
          <TextField label="User Name" variant="standard" onChange={(e) => setUser(e.target.value)} />
          <TextField label="Password" variant="standard" type="password" onChange={(e) => setPassword(e.target.value)} onKeyDown={(e) => enter(e)}/>
          {!showAlert ? <Button variant='contained' color='primary' className='loginBtn' onClick={login} >Login</Button> : <p className='login__alert'>{message}</p>}
        </article>
      </section>
    </main>
  )
}