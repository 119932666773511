import Footer from "../Footer";
import Header from "../Header";
import { useState } from 'react';
import '../../styles/dash.scss';
import { Box, Tab } from "@mui/material";
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import UpdateIcon from '@mui/icons-material/Update';
import LockIcon from '@mui/icons-material/Lock';
import SbcFuture from "./dashComp/SbcFuture";
import SbcPast from "./dashComp/SbcPast";

export default function SbcDash() {
  const [value, setValue] = useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  return (
    <main className="dash">
      <Header />
      <section className="dash__main">
      <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value} >
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="sbc tabs" centered>
            <Tab label="Future Events" value="1" icon={<UpdateIcon />}/>
            <Tab label="Past Events" value="2" icon={<LockIcon />}/>
          </TabList>
        </Box>
        <TabPanel value="1">
         <SbcFuture />
        </TabPanel>
        <TabPanel value="2">
          <SbcPast />
        </TabPanel>

      </TabContext>
    </Box>
      </section>
      <Footer />
    </main>
  )
}