import { Box, Typography, Button, Stack, TextField, Alert } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import EditIcon from '@mui/icons-material/Edit';


export default function Edit(props) {
  // const url = 'http://localhost:4100';
  const url = 'https://dev.pmwevents.com'
  const [attendee, setAttendee] = useState({})
  const [showModalAlert, setShowModalAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState('');
  // edit attendee modal
  const [showName, setShowName] = useState(false)
  const [showSurname, setShowSurname] = useState(false)
  const [showEmail, setShowEmail] = useState(false)
  const [showPhone, setShowPhone] = useState(false)
  const [showAddress, setShowAddress] = useState(false)
  const [showPostcode, setShowPostcode] = useState(false)
  const [showPaid, setShowPaid] = useState(false)


  useEffect(() => {
    setAttendee(props.att)
  }, [])

  const handleAttendeee = (field, change) => {
    attendee[field] = change
  }

  const editAttendee = () => {
    let data = {
      att: attendee,
      user: props.userId
    };

    console.log(attendee)

    axios.post(`${url}/sbc/edit-att`, data)
      .then((res) => {
        console.log(res.data)
        props.rel()
        props.close()
      }).catch((err) => {
        console.log(err)
        setMessage('Something went wrong, please try again')
        setSeverity('error')
        setShowModalAlert(true)
        setTimeout(() => {
          setShowModalAlert(false)
        }, 2500)
      })
  }



  return (
    <Box sx={props.style}>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        Edit fields for {props.att.name} {props.att.lastName}?
      </Typography>
      <Stack>
        <Typography variant="body1">Name: {attendee.name} <EditIcon color='primary' fontSize="small" onClick={() => setShowName(!showName)} /></Typography>
        {showName ? (
          <TextField label=" First Name" variant="standard" onChange={(e) => handleAttendeee('name', e.target.value)} />
        ) : null}
        <Typography variant="body1">Surname: {attendee.lastName} <EditIcon color='primary' fontSize="small" onClick={() => setShowSurname(!showSurname)} /></Typography>
        {showSurname ? (
          <TextField label="Last Name" variant="standard" onChange={(e) => handleAttendeee('lastName', e.target.value)} />
        ) : null}
        <Typography variant="body1">Email: {attendee.email} <EditIcon color='primary' fontSize="small" onClick={() => setShowEmail(!showEmail)} /></Typography>
        {showEmail ? (
          <TextField label="Email" variant="standard" onChange={(e) => handleAttendeee('email', e.target.value)} />
        ) : null}
        <Typography variant="body1">Phone: {attendee.phone} <EditIcon color='primary' fontSize="small" onClick={() => setShowPhone(!showPhone)} /></Typography>
        {showPhone ? (
          <TextField label="Phone" variant="standard" onChange={(e) => handleAttendeee('phone', e.target.value)} />
        ) : null}
        <Typography variant="body1">Address: {attendee.address} <EditIcon color='primary' fontSize="small" onClick={() => setShowAddress(!showAddress)} /></Typography>
        {showAddress ? (
          <TextField label="Address" variant="standard" onChange={(e) => handleAttendeee('address', e.target.value)} />
        ) : null}
        <Typography variant="body1">Postcode: {attendee.postcode} <EditIcon color='primary' fontSize="small" onClick={() => setShowPostcode(!showPostcode)} /></Typography>
        {showPostcode ? (
          <TextField label="Postcode" variant="standard" onChange={(e) => handleAttendeee('postcode', e.target.value)} />
        ) : null}
        <Typography variant="body1">Paid Amount: £{attendee.paidAmount} <EditIcon color='primary' fontSize="small" onClick={() => setShowPaid(!showPaid)} /></Typography>
        {showPaid ? (
          <TextField label="Paid Amount" variant="standard" onChange={(e) => handleAttendeee('paidAmount', e.target.value)} />
        ): null}

      </Stack>
      {showModalAlert ? (
        <Alert severity={severity} sx={{ marginTop: '2%' }} variant="filled">
          {message}
        </Alert>
      ) : (
        <Stack direction="row" justifyContent="center" alignItems="center" spacing={2} sx={{ marginTop: '2%' }}>
          <Button color='error' variant='outlined' onClick={() => props.close()}>Return</Button>
          <Button color='success' variant='contained' onClick={editAttendee}>Edit</Button>
        </Stack>
      )}

    </Box>

  )
}