import Footer from "../Footer";
import Header from "../Header";
import { useEffect, useState } from "react";
import axios from "axios";
import { getActiveEvents, getAllAttendees } from "../../utils/apiUtils";
import { decryptData } from "../../utils/cryptoUtils";
import Loader from "../../utils/Loader";
import { Alert, IconButton, Modal, Box, Typography, FormControl, InputLabel, Select, MenuItem, Stack, Button } from "@mui/material";
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import DownloadIcon from '@mui/icons-material/Download';

// EXCEL EXPORTS
import XLSX from 'sheetjs-style';
import * as FileSaver from 'file-saver';



const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: 'none',
  boxShadow: 24,
  p: 4,
  borderRadius: '1rem',
  '@media(max-width: 700px)': {
    width: '80vw',
    maxHeight: '80vh',
    overflowY: 'scroll',
  }
};


export default function PastEvents() {
  const url = 'https://dev.pmwevents.com';
  // const url = 'http://localhost:4100';

  const [events, setEvents] = useState([]);
  const [attendees, setAttendees] = useState([]);
  const [liveEvents, setLiveEvents] = useState([]);
  const [att, setAtt] = useState('');
  const [attId, setAttId] = useState('');
  const [movedEvent, setMovedEvent] = useState('');
  //alerts
  const [showAlert, setShowAlert] = useState(false);
  const [showModalAlert, setShowModalAlert] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState('');
  const [userId, setUserId] = useState('');
  const [eventName, setEventName] = useState("")
  // modal
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [reload, setReload] = useState(false)


  useEffect(() => {
    let id = localStorage.getItem('user');
    setUserId(id);
    axios.get(`${url}/events/past-events`)
      .then((res) => {
        setEvents(res.data)
      }).catch((err) => {
        console.log(`Could not get past events: ${err}`)
      })
  }, [reload])

  const getAtts = (eventId) => {
    setShowLoader(true)
    if (eventId === '-SELECT EVENT-') {
      setMessage('Please select an event')
      setSeverity('warning')
      setShowAlert(true);
      setShowLoader(false)
      setTimeout(() => {
        setShowAlert(false);
      }, 2000)
    } else {
      getAllAttendees(eventId)
        .then((data) => {
          if (data.length === 0) {
            setMessage('No tickets were bought for this event')
            setSeverity('warning')
            setShowAlert(true);
            setShowLoader(false)
            setTimeout(() => {
              setShowAlert(false);
            }, 2500)
          } else {
            const atts = data;
            for (let a = 0; a < atts.length; a++) {
              atts[a].name = decryptData(atts[a].name, process.env.REACT_APP_ATT_KEY)
              atts[a].email = decryptData(atts[a].email, process.env.REACT_APP_ATT_KEY)
              atts[a].phone = decryptData(atts[a].phone, process.env.REACT_APP_ATT_KEY)
              atts[a].lastName = decryptData(atts[a].lastName, process.env.REACT_APP_ATT_KEY)
            }
            setAttendees(atts);
            setShowLoader(false);
          }
        })
      for (let i = 0; i < events.length; i++) {
        if (events[i].eventId == eventId) {
          setEventName(events[i].eventName)
        }
      }
    }
  }

  const confirmMove = async (attendeeName, attendeeId) => {
    setAtt(attendeeName);
    setAttId(attendeeId);
    await getActiveEvents().then((data) => {
      setLiveEvents(data)
    }).catch((err) => {
      console.log(`Could not get live events: ${err}`)
    })

    handleOpen();
  }

  const moveAttendee = () => {
    if (movedEvent === '') {
      setMessage('Please select an event')
      setSeverity('warning')
      setShowModalAlert(true)
      setTimeout(() => {
        setShowModalAlert(false)
      }, 2000)
    } else {
      let data = {
        userId: userId,
        attId: attId,
        eventId: movedEvent,
      }
      axios.put(`${url}/events/move-att`, data)
        .then((res) => {
          handleClose()
          setReload(!reload)
        }).catch((err) => {
          console.log(err)
          setMessage('Something went wrong, please try again')
          setSeverity('error')
          setShowAlert(true);
          setTimeout(() => {
            setShowAlert(false);
          }, 2000)
        })
    }
  }

  const exportExcel = () => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    // const fileExtension = '.xlsx';

    let excelExportsData = [];

    attendees.forEach((obj) => {
      let newObj = {};

      Object.keys(obj).forEach((line) => {
        newObj[line] = obj[line];
      });
      excelExportsData.push(newObj);
    });

    for (let e = 0; e < excelExportsData.length; e++) {
      if (excelExportsData[e].checkIn) {
        let days = excelExportsData[e].checkIn

        for (let d = 0; d < days.length; d++) {
          let day = days[d]
          Object.keys(day).forEach((line) => {
            if (day[line] === true) {
              excelExportsData[e]['checked'] = 'YES'
            } else {
              excelExportsData[e]['checked'] = 'NO'
            }
          })
        }
      } else {
        excelExportsData[e]['checked'] = 'NO'
      }
    }


    const exportToExcel = async () => {
      const ws = XLSX.utils.json_to_sheet(excelExportsData);
      const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });

      FileSaver.saveAs(
        data, `Attendees for event: ${eventName}`
      )
    }

    exportToExcel();
  }


  return (
    <main className="events__management">
      <Header />
      <section className="download__main">
        <h2>Select a past event</h2>
        <select onChange={(e) => getAtts(e.target.value)}>
          <option>-SELECT EVENT-</option>
          {events.map((event) => {
            return (
              <option key={event.eventId} value={event.eventId}>{event.eventName}</option>
            )
          })}
        </select>
        {showLoader ? <Loader /> : null}
        {showAlert ? (
          <Alert severity={severity} sx={{ marginTop: '2%' }} variant="filled">
            {message}
          </Alert>
        ) : null}
        {attendees.length > 0 ? (
          <>
            <table>
              <thead>
                <th>Order ID</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Status</th>
                <th>Attended</th>
                <th>Move Event</th>
              </thead>
              <tbody>
                {attendees.map((att, index) => {
                  let backColor, attended;
                  let fontColor = '#131b26';
                  if (att.status === "cancelled") {
                    backColor = "#E8160C";
                    fontColor = '#fff';
                  }

                  if (att.checkIn == null) {
                    attended = "NO"
                  } else if (Object.keys(att.checkIn).length < 1){
                    attended = "NO";
                    backColor = "#E8160C";
                    fontColor = '#fff';
                  }
                  else if (att.checkIn[0].day1 == false) {
                    attended = "NO";
                    backColor = "#E8160C";
                    fontColor = '#fff';
                  } else if (att.checkIn[0].day1 == true) {
                    attended = "YES"
                  } 

                  return (
                    <tr key={index} style={{ backgroundColor: backColor, color: fontColor }}>
                      <td>{att.attendeeId}</td>
                      <td>{att.name}</td>
                      <td>{att.lastName}</td>
                      <td>{att.status}</td>
                      <td>{attended}</td>
                      <td><IconButton><ChangeCircleIcon color='primary' fontSize="large" onClick={() => confirmMove(att.name, att.attendeeId)} /></IconButton></td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
            <Button color='primary' variant='contained' sx={{ marginBottom: "2%" }} endIcon={<DownloadIcon />} onClick={exportExcel}>Download attendees</Button>
          </>
        ) : null}
      </section>
      {/* Move attendee modal */}
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Please select the event you want to move {att} to
          </Typography>
          <FormControl fullWidth>
            <InputLabel id="select-label">Events</InputLabel>
            <Select
              labelId="select-label"
              id="select"
              label="Events"
              value={movedEvent}
              onChange={(e) => setMovedEvent(e.target.value)}
            >
              {liveEvents.map((event) => {
                return (
                  <MenuItem key={event.eventId} value={event.eventId}>{event.eventName}</MenuItem>

                )
              })}
            </Select>
          </FormControl>
          {showModalAlert ? (
            <Alert severity={severity} sx={{ marginTop: '2%' }} variant="filled">
              {message}
            </Alert>
          ) : (
            <Stack direction="row" justifyContent="center" alignItems="center" spacing={2} sx={{ margin: '2% auto' }}>
              <Button color='error' variant='outlined' onClick={handleClose}>Return</Button>
              <Button color='success' variant='contained' onClick={moveAttendee}>Move</Button>
            </Stack>
          )}
        </Box>
      </Modal>
      <Footer />
    </main>
  )
}